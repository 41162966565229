<template>
  <div
    class="mass-action-result"
  >
    <div class="mass-actions-page-block-name">Результаты</div>

    <div style="margin-top: 10px">
      <div>Успешно выполнено: {{result.count_success}}</div>
      <div>Не выполнено: {{result.count_error}}</div>

      <div
        v-if="!!result.errors.length && errorProps && !!errorProps.length"
      >
        <div>Элементы с ошибками:</div>
        <el-table
          size="mini"
          :data="result.errors"
          border
          :show-header="false"
          style="width: 100%"
        >
          <el-table-column
            v-for="errorProp in errorProps"
            :key="errorProp"
          >
            <template slot="header"></template>
            <template v-slot="scope">
              {{ $companyConfiguration.helpers.getObjectPropByString(scope.row, errorProp) }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>



  </div>
</template>

<script>

import {off, on} from "element-ui/src/utils/dom";
import Paginator from "@/components/Paginator.vue";
import ReportResult from "@/components/report/builder/ReportResult.vue";

export default {
  name: "mass-action-result",
  components: {ReportResult, Paginator},
  props: {
    result:{type: Object},
    errorProps:{type: Array},
  },

  data() {
    return {

    }
  },

  methods: {

  },


}
</script>

<style>

</style>